// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BarChartOutlined,
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  CloudOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StockOutlined,
  StopOutlined,
  UserOutlined
} from '@ant-design/icons';

import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import HorizontalSplitOutlinedIcon from '@mui/icons-material/HorizontalSplitOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import SortIcon from '@mui/icons-material/Sort';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  CloudOutlined,
  DatabaseOutlined,
  BarChartOutlined,
  StockOutlined,
  UserOutlined,
  HorizontalSplitIcon,
  HorizontalSplitOutlinedIcon,
  WavesOutlinedIcon,
  Inventory2OutlinedIcon,
  StoreMallDirectoryOutlinedIcon,
  ListAltIcon,
  DashboardIcon,
  SortIcon,
  RequestQuoteOutlinedIcon,
  RequestQuoteRoundedIcon,
  AttachMoneyRoundedIcon
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'menu',
  title: <FormattedMessage id="Menu" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Estadisticas" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardIcon
    },
    {
      id: 'clasificacion',
      title: <FormattedMessage id="Clasificacion" />,
      type: 'item',
      url: '/clasificacion',
      icon: icons.SortIcon
    },
    {
      id: 'registros_entero',
      title: <FormattedMessage id="Registros Entero" />,
      type: 'item',
      url: '/registros-entero',
      icon: icons.HorizontalSplitIcon
    },
    {
      id: 'registros_cola',
      title: <FormattedMessage id="Registros Cola" />,
      type: 'item',
      url: '/registros-cola',
      icon: icons.HorizontalSplitOutlinedIcon
    },
    {
      id: 'sectores',
      title: <FormattedMessage id="Sectores" />,
      type: 'item',
      url: '/sectores',
      icon: icons.StoreMallDirectoryOutlinedIcon
    },
    {
      id: 'piscinas',
      title: <FormattedMessage id="Piscinas" />,
      type: 'item',
      url: '/piscinas',
      icon: icons.WavesOutlinedIcon
    },
    {
      id: 'empacadoras',
      title: <FormattedMessage id="Empacadoras" />,
      type: 'item',
      url: '/empacadoras',
      icon: icons.Inventory2OutlinedIcon
    },
    {
      id: 'precio',
      title: <FormattedMessage id="Precios Históricos" />,
      type: 'item',
      url: '/precios',
      icon: icons.AttachMoneyRoundedIcon
    },
    {
      id: 'precio_clasificacion',
      title: <FormattedMessage id="Comparar Precios" />,
      type: 'item',
      url: '/preciosClasificacion',
      icon: icons.AttachMoneyRoundedIcon
    },
    {
      id: 'precio_entero',
      title: <FormattedMessage id="Crear Precio Entero" />,
      type: 'item',
      url: '/preciosEntero',
      icon: icons.RequestQuoteRoundedIcon
    },
    {
      id: 'precio_cola',
      title: <FormattedMessage id="Crear Precio Cola" />,
      type: 'item',
      url: '/preciosCola',
      icon: icons.RequestQuoteOutlinedIcon
    },
    {
      id: 'grupo_economico',
      title: <FormattedMessage id="Grupo Economico" />,
      type: 'item',
      url: '/grupo-economico',
      icon: icons.ListAltIcon
    },
    {
      id: 'usuarios',
      title: <FormattedMessage id="Usuarios" />,
      type: 'item',
      url: '/usuarios',
      icon: icons.UserOutlined
    }
  ]
};

export default other;
